import { useEffect, useState } from 'react'

import { getStreamToken } from '@services/api'

const baseUrl = process.env.REACT_APP_API_URL_UPDATE

const useEventSource = () => {
	const [id, setId] = useState(null)
	const [saving, setSaving] = useState({})
	const [balance, setBalance] = useState({})
	const [charging, setCharging] = useState(null)
	const [chargingETA, setChargingEta] = useState(null)
	const [fullyCharged, setFullyCharged] = useState(null)
	const [pluggedIn, setPluggedIn] = useState(null)
	const [actualSoc, setActualSoc] = useState(0)
	const [targetSoc, setTargetSoc] = useState(0)
	const [chargingUsingDC, setChargingUsingDC] = useState(false)

	useEffect(() => {
		let eventSource
		const setupEventSource = async () => {
			const streamToken = await getStreamToken()

			if (id && streamToken) {
				eventSource = new EventSource(
					`${baseUrl}/reports/api/v1/users/self/vehicles/${id}/statistics/stream?streamToken=${streamToken}`
				)

				eventSource.onmessage = (event) => {
					const data = JSON.parse(event.data)
					if (data.costSavings) setSaving(data.costSavings)
					if (data.co2balance) setBalance(data.co2balance)
					if (data.chargingETA) setChargingEta(data.chargingETA)
					if (data.lastChargingStatus.actualSoc) setActualSoc(data.lastChargingStatus.actualSoc)
					if (data.lastChargingStatus.targetSoc) setTargetSoc(data.lastChargingStatus.targetSoc)

					setCharging(data.lastChargingStatus.charging)
					setFullyCharged(data.lastChargingStatus.fullyCharged)
					setPluggedIn(data.lastChargingStatus.pluggedIn)
					setChargingUsingDC(data.lastChargingStatus.chargingUsingDC)
				}

				eventSource.onerror = (error) => {
					console.log('EventSource error:', error)
					eventSource.close()
				}
			}
		}

		setupEventSource()

		return () => {
			if (eventSource) {
				eventSource.close()
			}
		}
	}, [id])

	return {
		saving,
		balance,
		charging,
		chargingETA,
		fullyCharged,
		pluggedIn,
		actualSoc,
		targetSoc,
		setId,
		chargingUsingDC,
	}
}
export default useEventSource
