/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Modal, Input, InputNumber, Popover, Divider, Select } from 'antd'
import styles from './style.module.scss'
import {
	deleteENODELink,
	deleteUser,
	getVCFLink,
	getVehicleList,
	getVehicleStatus,
	updateOperatingMode,
	deleteVehicle,
	saveToken,
	addVehicle,
	logOut,
} from '@services/api'
import useEventSource from '@hooks/useSource'
import { DownOutlined, PlusCircleOutlined, DeleteOutlined, LogoutOutlined } from '@ant-design/icons'

import {
	DividerComponent,
	CarIcon,
	ChargerIcon,
	ChargeIconActive,
	ClockIcon,
	BatteryIcon,
	OptimizationIcon,
	OptimizationCharger,
	ReportButton,
	PlugCcsDcSIcon,
} from './components'

const AccountTab = () => {
	const navigate = useNavigate()
	const [isLinked, setLinked] = useState(false)
	const [link, setLink] = useState('')
	const [vehiclesList, setList] = useState([])
	const [isAgreed, setIsAgreed] = useState(false)
	const [showAgreeModal, setShowAgreeModal] = useState(false)
	const [selected, setSelected] = useState(null)
	const [isControlled, setIsControlled] = useState(false)
	const [showConfirmation, setConfirmation] = useState(null)
	const [showAdd, setShowAdd] = useState(false)
	const [newVehicle, setNewVehicle] = useState({})
	const [error, setError] = useState('')
	const [currentVehicle, setCurrentVehicle] = useState({})
	const [vehicleObj, setVehicleObj] = useState({})
	const [activeStep, setActiveStep] = useState('week')
	const {
		actualSoc,
		balance,
		charging,
		chargingETA,
		chargingUsingDC,
		fullyCharged,
		pluggedIn,
		saving,
		setId,
		targetSoc,
	} = useEventSource()

	const [vehiclesIds, setVehiclesIds] = useState([])
	const [initialChangeDone, setInitialChangeDone] = useState(false)
	const [showDeleteModal, setShowDeleteModal] = useState(false)

	const onChangeVehicle = async (id) => {
		setId(id)
		setSelected(id)
		setCurrentVehicle(...vehiclesList.filter((e) => e.id === id))
	}
	const changeVehicleObj = () => {
		setVehicleObj({
			actualSoc,
			charging,
			chargingETA,
			chargingUsingDC,
			fullyCharged,
			pluggedIn,
			targetSoc,
		})
	}
	const chargingStatus = (charging, fullyCharged) => {
		let status
		if (charging) {
			status = 'charging'
		} else if (!charging && !fullyCharged) {
			status = 'not charging'
		} else if (fullyCharged) {
			status = 'fully charged'
		} else {
			status = 'N/A'
		}
		return status
	}

	const fetchVehicles = useCallback(async () => {
		const response = await getVehicleList()
		if (response) {
			const data = response.map((vehicle) => getVehicleStatus(vehicle.id))
			const updatedList = await Promise.all(data)
			const dataMap = response.map((vehicle, index) => ({
				...vehicle,
				...updatedList[index],
			}))
			setList(dataMap)
			setVehiclesIds(response.map((vehicle) => vehicle.id))
		}
	}, [])
	const fetchLink = useCallback(async () => {
		const params = new URLSearchParams(window.location.search)
		const code = params.get('code')
		if (code) {
			setShowAgreeModal(true)
		} else {
			const linkData = await getVCFLink()
			setLinked(linkData?.isAlreadyLinked)
			if (linkData?.isAlreadyLinked) {
				setIsAgreed(true)
			} else {
				setLink(linkData?.linkUrl || '/')
			}
		}
	}, [window.location.search])

	const handleLogin = () => {
		window.location.replace(link)
	}

	const logoutHandler = async () => {
		try {
			await logOut()
			localStorage.clear()
		} catch (error) {
			console.log(error)
		}
		localStorage.clear()
		window.location.href = '/'
	}

	const handleDelete = async () => {
		await deleteUser()
		window.location.href = '/'
		localStorage.clear()
	}

	const handleDisconnect = async () => {
		await deleteENODELink()
		setList([])
		setIsAgreed(false)
		// window.location.href = "/account";
	}

	const handleControlMode = async (id, value) => {
		try {
			await updateOperatingMode(id, isControlled)
			await fetchVehicles()
			onChangeVehicle(id)
		} catch (error) {
			console.error('Error updating operating mode:', error)
		}
		setConfirmation(null)
	}

	const handleSwitch = () => {
		setIsControlled((prev) => !prev)
		setConfirmation({
			value: isControlled,
			id: currentVehicle.id,
		})
	}

	const handleCancel = () => {
		setConfirmation(null)
		setIsControlled((prev) => !prev)
	}

	const handleDeleteVehicle = async (id) => {
		await deleteVehicle(id)
		if (selected === id) {
			const remainingVehicles = vehiclesList.filter((vehicle) => vehicle.id !== id)
			if (remainingVehicles.length > 0) {
				const newSelected = remainingVehicles[0].id
				await fetchVehicles()
				onChangeVehicle(newSelected)
			} else {
				setSelected(null)
			}
		}
	}

	const handleAgree = async () => {
		const params = new URLSearchParams(window.location.search)
		const code = params.get('code')

		try {
			await saveToken(code)
			navigate.replace('/account')
			setIsAgreed(true)
			setShowAgreeModal(false)
		} catch (e) {
			console.error(e)
		}
	}

	const handleAddVehicle = async () => {
		if (newVehicle.name && newVehicle.vin && (newVehicle.batteryCapacity === 0 || newVehicle.batteryCapacity)) {
			const response = await addVehicle(newVehicle)
			if (response) {
				setError(response)
			} else {
				await fetchVehicles()
				setShowAdd(false)
			}
		}
	}
	useEffect(() => {
		fetchVehicles()
		fetchLink()
	}, [fetchVehicles, isAgreed, fetchLink])
	useEffect(() => {
		changeVehicleObj()
	}, [charging, chargingETA, fullyCharged, pluggedIn, actualSoc, targetSoc])
	useEffect(() => {
		if (vehiclesList.length > 0 && vehiclesIds.length > 0 && !initialChangeDone) {
			onChangeVehicle(vehiclesIds[0])
			setInitialChangeDone(true)
		}
	}, [vehiclesList, vehiclesIds, initialChangeDone])
	useEffect(() => {
		setIsControlled(currentVehicle.controlMode === 'CONTROLLED')
	}, [currentVehicle])
	useEffect(() => {
		if (initialChangeDone) {
			onChangeVehicle(selected)
		}
	}, [vehiclesList])
	return (
		<div className={styles.chargerBevContainer}>
			<Popover title={<h2 align="center">Logout</h2>}>
				<Button
					icon={<LogoutOutlined style={{ fontSize: '28px' }} />}
					onClick={() => {
						logoutHandler()
					}}
					className={styles.desktopLogoutButton}
				/>
			</Popover>
			<div className={styles.mobileLogoutContainer}>
				<Button
					icon={<LogoutOutlined style={{ fontSize: '28px' }} />}
					onClick={() => {
						logoutHandler()
					}}
					className={styles.mobileLogoutButton}
				>
					Log Out
				</Button>
			</div>
			{!isLinked && (
				<div className={styles.sectionContainer}>
					<Button type="primary" htmlType="submit" className={styles.button} disabled={!link} onClick={handleLogin}>
						Login with MyAudi Credentials
					</Button>
				</div>
			)}

			<div className={styles.bevOptions}>
				<Popover title={<h2 align="center">Delete Vehicle</h2>}>
					<div className={styles.bevIcons}>
						<DeleteOutlined onClick={() => handleDeleteVehicle(selected)} />
					</div>
				</Popover>
				<div style={{ position: 'relative' }}>
					<Select
						size="large"
						suffixIcon={<DownOutlined className={styles.dropDownIcon} />}
						className={styles.bevDropdown}
						placeholder="Select Vehicle"
						value={currentVehicle?.name}
						showSearch={false}
						onChange={onChangeVehicle}
						options={vehiclesList.map((item) => ({
							label: item.name,
							value: item.id,
						}))}
						variant="borderless"
					/>
				</div>
				<Popover title={<h2 align="center">Add Vehicle</h2>}>
					<div className={styles.bevIcons}>
						<PlusCircleOutlined onClick={() => setShowAdd(true)} />
					</div>
				</Popover>
			</div>

			<div
				className={[
					styles.topCharger,
					vehicleObj.pluggedIn ? styles.topChargerActive : '',
					!vehicleObj.pluggedIn && !vehicleObj.charging ? styles.topChargerInActive : '',
				].join(' ')}
			>
				<CarIcon charging={vehicleObj.charging} isPlug={vehicleObj.pluggedIn} isDc={vehicleObj.chargingUsingDC} />
				{vehicleObj.pluggedIn ? (
					<ChargeIconActive
						isCharging={vehicleObj.charging}
						isPlug={vehicleObj.pluggedIn}
						isDc={vehicleObj.chargingUsingDC}
					/>
				) : (
					<ChargerIcon />
				)}
				{vehicleObj.chargingUsingDC && <PlugCcsDcSIcon fill="#f37037" className={styles.plugCcsDc} />}
				{vehicleObj.chargingUsingDC && <span className={styles.dcCharging}>HPC</span>}
			</div>

			<hr />

			<div className={styles.timeCharger}>
				<div className={styles.blockCharger}>
					{vehicleObj.pluggedIn && <span>{vehicleObj.actualSoc}%</span>}
					<BatteryIcon capacity={vehicleObj.actualSoc} isCharging={vehicleObj.charging} isPlug={vehicleObj.pluggedIn} />
				</div>
				<div className={styles.blockCharger}>
					{vehicleObj.pluggedIn && (
						<span className={styles.green}>{chargingStatus(vehicleObj.charging, vehicleObj.fullyCharged)}</span>
					)}
					<DividerComponent
						actual={vehicleObj.actualSoc}
						isCharging={vehicleObj.charging}
						isPlug={vehicleObj.pluggedIn}
						target={vehicleObj.targetSoc}
						chargingEst={vehicleObj.chargingETA}
					/>
				</div>
				<div className={styles.iconClockContainer}>
					<ClockIcon className={vehicleObj.pluggedIn ? styles.clockIcon : styles.hidden} />
				</div>
			</div>
			<Divider className={styles.dividerCustom} />
			{<OptimizationCharger daily={saving[activeStep]} />}
			<Divider className={styles.dividerCustom} />
			<div className={styles.saveCharger}>
				<div className={styles.informationCharger}>
					<span>{balance[activeStep] ?? 0}%</span>
					<OptimizationIcon />
				</div>
				<div className={styles.stepper}>
					<span className={activeStep === 'week' ? styles.active : ''} onClick={() => setActiveStep('week')}>
						week
					</span>
					<span className={activeStep === 'month' ? styles.active : ''} onClick={() => setActiveStep('month')}>
						month
					</span>
					<span className={activeStep === 'year' ? styles.active : ''} onClick={() => setActiveStep('year')}>
						year
					</span>
				</div>
			</div>
			<Divider className={styles.bottomDivider} />
			<Button className={[styles.chargeNow, styles.active].join(' ')} onClick={handleSwitch}>
				{isControlled ? `Charge now > 100%` : 'Activate optimized charging'}
			</Button>
			<Divider className={styles.bottomDivider} />
			<div className={styles.buttonContainer}>
				<Button className={styles.deleteButton} type="primary" onClick={handleDisconnect} disabled={link}>
					Disconnect with myAudi
				</Button>
				<Button className={styles.deleteButton} type="primary" onClick={() => setShowDeleteModal(true)}>
					Delete Pool-System Account
				</Button>
			</div>

			<Modal
				open={showConfirmation}
				onOk={() => handleControlMode(currentVehicle.id, showConfirmation.value)}
				onCancel={handleCancel}
				okText={'Confirm'}
				cancelText={'Cancel'}
				centered
			>
				<p>
					{isControlled
						? 'Are you sure you want to take part in optimized charging as part of the Audi Gigaschwarm?'
						: 'Are you sure that you want to set your target SoC to 100% and therefore no optimized charging will take place until the next plugging in/charging process or until you reactivate optimized charging?'}
				</p>
			</Modal>

			<Modal
				open={showAgreeModal}
				okText={'Agree'}
				cancelText={'Cancel'}
				onCancel={() => {
					fetchLink()
					setShowAgreeModal(false)
				}}
				onOk={handleAgree}
				centered
			>
				<p>Do you agree that your data will be used by the pooling system?</p>
			</Modal>

			<Modal
				title={'Add New Vehicle'}
				open={showAdd}
				centered
				onCancel={() => setShowAdd(false)}
				onOk={handleAddVehicle}
				okText={'Add'}
				cancelText={'Cancel'}
				disable
			>
				<div className={styles.formContainer}>
					<span className={styles.formLabel}>Name</span>
					<Input onChange={(e) => setNewVehicle((prev) => ({ ...prev, name: e.target.value }))} />
					<span className={styles.formLabel}>VIN</span>
					<Input
						status={error ? 'error' : ''}
						onChange={(e) => setNewVehicle((prev) => ({ ...prev, vin: e.target.value }))}
					/>
					<span className={styles.error}>{error}</span>
					<span className={styles.formLabel}>Battery Capacity</span>
					<InputNumber min={0} onChange={(value) => setNewVehicle((prev) => ({ ...prev, batteryCapacity: value }))} />
				</div>
			</Modal>

			<Modal
				title={'Delete Pool-System Account'}
				open={showDeleteModal}
				centered
				onCancel={() => setShowDeleteModal(false)}
				onOk={handleDelete}
				okText={'Delete'}
			>
				<p>Are you sure you want to delete your Pool-System Account?</p>
			</Modal>

			<ReportButton />
		</div>
	)
}

export default AccountTab
